import { CommonModule } from '@angular/common';
import { Component, inject } from '@angular/core';

import { LoadingBarService } from '@ynmstudio/utils/loading-bar';
import { LogoService } from './logo.service';
import { toSignal } from '@angular/core/rxjs-interop';

@Component({
  selector: 'app-logo',
  imports: [CommonModule],
  templateUrl: './logo.component.svg',
  styleUrl: './logo.component.scss',
  providers: [],
})
export class LogoComponent {
  #logoService = inject(LogoService);
  #loader = inject(LoadingBarService);

  progress = toSignal(this.#loader.value$);

  invert = this.#logoService.invertLogo;
}
