import {
  trigger,
  state,
  style,
  transition,
  animate,
  query,
  group,
} from '@angular/animations';
import { CommonModule } from '@angular/common';
import { Component, HostListener, signal, viewChild } from '@angular/core';
import { RouterLink, RouterLinkActive } from '@angular/router';

@Component({
  selector: 'a[main-nav-item]',
  imports: [CommonModule],
  templateUrl: './main-nav-item.component.html',
  styleUrl: './main-nav-item.component.scss',
  hostDirectives: [
    { directive: RouterLink, inputs: ['routerLink:navRouterLink'] },
    {
      directive: RouterLinkActive,
      inputs: ['routerLinkActive:navRouterLinkActive'],
      outputs: ['isActiveChange'],
    },
  ],
  animations: [
    trigger('openClose', [
      transition(':enter', [
        style({ opacity: 0, width: 0 }),
        query('.dot', style({ transform: 'translateY(-50%) scale(0.5)' })),
        group([
          animate(
            '0.3s cubic-bezier(0.2, 1.3, 0.7, 1)',
            style({ opacity: 1, width: '*' }),
          ),
          query(
            '.dot',
            animate(
              '0.1s cubic-bezier(0.2, 1.3, 0.7, 1)',
              style({ transform: ' translateY(-50%) scale(1)' }),
            ),
          ),
        ]),
      ]),
      transition(':leave', [
        group([
          query(
            '.dot',
            animate(
              '0.3s cubic-bezier(0.2, 1.3, 0.7, 1)',
              style({ transform: 'translateY(-50%) scale(0.5)' }),
            ),
          ),
          animate(
            '0.3s cubic-bezier(0.2, 1.3, 0.7, 1)',
            style({ opacity: 0, width: 0 }),
          ),
        ]),
      ]),
    ]),
  ],
})
export class MainNavItemComponent {
  isActive = signal(false);
  isHovered = signal(false);

  @HostListener('isActiveChange', ['$event']) onActiveChange(event: any) {
    this.isActive.set(event);
  }

  @HostListener('mouseenter') onMouseOver() {
    this.isHovered.set(true);
  }

  @HostListener('mouseleave') onMouseLeave() {
    this.isHovered.set(false);
  }
}
