import { inject } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivateFn,
  Router,
  RouterStateSnapshot,
} from '@angular/router';
import { GetRedirectGQL } from 'generated/types.graphql-gen';
import posthog from 'posthog-js';
import { lastValueFrom } from 'rxjs';

export const canRedirectDeeplink: CanActivateFn = async (
  route: ActivatedRouteSnapshot,
  state: RouterStateSnapshot,
) => {
  const resolveGql = inject(GetRedirectGQL);
  const router = inject(Router);
  const { offer: offerId, ...queryParams } = route.queryParams;
  const { data } = await lastValueFrom(
    resolveGql.fetch({ site: route.data['site'], offerId }),
  );
  const offer = data?.offersEntries?.at(0);
  const museum = offer?.museum.at(0);
  posthog.capture('offer_redirect', {
    offerId,
    queryParams,
  });
  if (offer && museum) {
    return router.navigate(
      ['', { outlets: { primary: museum?.url?.slice(1), aside: offer?.uri } }],
      { queryParamsHandling: 'merge', queryParams },
    );
  } else if (offer) {
    return router.navigate([offer?.url ?? '']);
  } else {
    return router.navigate([museum?.url ?? '']);
  }
};
