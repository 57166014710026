import { Router, Routes } from '@angular/router';
import { pageResolver } from './routes/page/page.resolver';
import { homepageResolver } from './routes/homepage/homepage.resolver';
import { offerResolver } from './routes/offer/detail/offer-detail.resolver';
import { titleResolver } from './utils/resolvers/title.resolver';
import { navigationResolver } from './utils/resolvers/navigation.resolver';
import { inject } from '@angular/core';

export const routes: Routes = [
  {
    path: '**',
    loadComponent: () => import('./routes/error/error.component'),
    title: () =>
      `Error ${inject(Router).getCurrentNavigation()?.initialUrl.queryParamMap.get('errorCode') ?? 404} – Museumsdienst Berlin`,
    canMatch: [
      () =>
        inject(Router)
          .getCurrentNavigation()
          ?.initialUrl.queryParamMap.has('errorCode'),
    ],
  },
  {
    path: 'en',
    data: {
      site: 'museumsdienstBerlin_en',
      revalidate: 0,
    },
    runGuardsAndResolvers: 'always',
    title: titleResolver,
    resolve: {
      navigation: navigationResolver,
    },
    children: [
      {
        path: '',
        loadComponent: () => import('./routes/homepage/homepage.component'),
        resolve: {
          page: homepageResolver,
        },
      },
      {
        path: 'programmes',
        loadChildren: () => import('./routes/offer/offer.routes'),
      },
      {
        path: 'museums',
        loadChildren: () => import('./routes/museum/museum.routes'),
      },
      {
        path: 'exhibitions',
        loadChildren: () => import('./routes/exhibition/exhibition.routes'),
      },
      {
        path: '**',
        loadComponent: () => import('./routes/page/page.component'),
        resolve: {
          page: pageResolver,
        },
      },
    ],
  },
  {
    path: '',
    data: {
      site: 'museumsdienstBerlin_de',
      revalidate: 0,
    },
    runGuardsAndResolvers: 'always',
    title: titleResolver,
    resolve: {
      navigation: navigationResolver,
    },
    children: [
      {
        path: '',
        loadComponent: () => import('./routes/homepage/homepage.component'),
        resolve: {
          page: homepageResolver,
        },
      },
      {
        path: 'programme',
        loadChildren: () => import('./routes/offer/offer.routes'),
      },
      {
        path: 'ausstellungen',
        loadChildren: () => import('./routes/exhibition/exhibition.routes'),
      },
      {
        path: 'museen',
        loadChildren: () => import('./routes/museum/museum.routes'),
      },
      {
        path: '**',
        /*
         * @description
         *
         * The following line can only be used, if the component uses a default export `export default class DefaultComponent {}`.
         * Otherwise the promise needs to be resolved with `.then(c => c.DefaultComponent)`.
         * Angular CLI is not creating components with default exports by default, this has to be done manually.
         *
         */
        // loadComponent: () => import('./pages/page/page.component').then(c => c.PageComponent),
        loadComponent: () => import('./routes/page/page.component'),
        resolve: {
          page: pageResolver,
        },
      },
    ],
  },

  {
    outlet: 'aside',
    path: 'programme/:slug',
    loadComponent: () => import('./routes/offer/detail/offer-detail.component'),
    resolve: {
      page: offerResolver,
    },
    data: {
      site: 'museumsdienstBerlin_de',
    },
  },
  {
    outlet: 'aside',
    path: 'programmes/:slug',
    loadComponent: () => import('./routes/offer/detail/offer-detail.component'),
    resolve: {
      page: offerResolver,
    },
    data: {
      site: 'museumsdienstBerlin_en',
    },
  },
];
