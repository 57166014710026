import { HighContrastModeDetector } from '@angular/cdk/a11y';
import { DOCUMENT, isPlatformServer } from '@angular/common';
import {
  ApplicationConfig,
  Injectable,
  PLATFORM_ID,
  computed,
  effect,
  inject,
} from '@angular/core';
import { patchState, signalState } from '@ngrx/signals';

export enum FontSize {
  Small = 1,
  Medium = 1.15,
  Large = 1.3,
}

type A11yState = { highContrast: boolean; fontSize: FontSize };

const a11yState: A11yState = {
  highContrast: false,
  fontSize: FontSize.Small,
};

@Injectable({
  providedIn: 'root',
})
export class A11yService {
  readonly #state = signalState(a11yState);

  readonly highContrast = this.#state.highContrast;
  readonly fontSize = this.#state.fontSize;
  readonly fontSizeClass = computed(() => {
    return `a11y${FontSize[this.fontSize()]}`;
  });
  oldFontSizeClass = `a11y${FontSize[this.#state.fontSize()]}`;

  #highContrastModeDetector = inject(HighContrastModeDetector);
  #document = inject(DOCUMENT);
  #platform_id = inject(PLATFORM_ID);

  constructor() {
    patchState(this.#state, {
      highContrast:
        this.#highContrastModeDetector.getHighContrastMode() > 0 ? true : false,
    });

    // 👇 Performing side effects.
    // effect(() => console.log('a11yState', this.#state()));
    effect(() => {
      if (this.highContrast()) {
        this.#document.documentElement.classList.add('dark');
      } else {
        this.#document.documentElement.classList.remove('dark');
      }
    });
    effect(() => {
      this.#document.documentElement.classList.remove(this.oldFontSizeClass);
      this.#document.documentElement.classList.add(this.fontSizeClass());
      this.oldFontSizeClass = this.fontSizeClass();
      this.#document.documentElement.setAttribute(
        'style',
        `--a11y-font-size: ${this.fontSize()};`,
      );
      if (isPlatformServer(this.#platform_id)) return;
      window.dispatchEvent(new Event('resize'));
    });
  }

  public toggleHighContrast() {
    patchState(this.#state, { highContrast: !this.#state.highContrast() });
  }
  public toggleFontSize() {
    patchState(this.#state, {
      fontSize:
        this.#state.fontSize() === FontSize.Small
          ? FontSize.Medium
          : this.#state.fontSize() === FontSize.Medium
            ? FontSize.Large
            : FontSize.Small,
    });
  }
}

export function provideA11yService(): ApplicationConfig['providers'] {
  return [A11yService];
}
