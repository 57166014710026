import { Component, inject } from '@angular/core';
import { NavigationService } from '../../utils/services/navigation.service';
import { CommonModule } from '@angular/common';
import { RouterLink, RouterLinkActive } from '@angular/router';
import { RelativeUrlPipe } from '../../utils/pipes/relative-url.pipe';
import { TranslocoPipe } from '@jsverse/transloco';

@Component({
  selector: 'footer[app-footer]',
  imports: [
    CommonModule,
    RouterLink,
    RouterLinkActive,
    RelativeUrlPipe,
    TranslocoPipe,
  ],
  templateUrl: './footer.component.html',
  styleUrl: './footer.component.scss',
  host: {
    class:
      'full-width layout-grid bg-black text-white  mt-48 pt-dynamic-xs  pb-dynamic-xl 2xl:py-dynamic',
  },
})
export class FooterComponent {
  navigationService = inject(NavigationService);
}
