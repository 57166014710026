import { DOCUMENT } from '@angular/common';
import { inject } from '@angular/core';
import {
  ResolveFn,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
} from '@angular/router';
import { TranslocoService } from '@jsverse/transloco';
import { GetTitleGQL, GetTitleQuery } from 'generated/types.graphql-gen';
import { EMPTY, of, switchMap } from 'rxjs';
import { extractMetaTags } from '../services/navigation.service';

export const titleResolver: ResolveFn<string> = (
  route: ActivatedRouteSnapshot,
  state: RouterStateSnapshot,
) => {
  const document = inject(DOCUMENT);
  const routeUrl = route.url.toLocaleString();
  const urlWithoutQueryParams = state.url.split('?').at(0) ?? state.url;
  const urlWithoutRoute = urlWithoutQueryParams.substring(
    urlWithoutQueryParams.indexOf(routeUrl) + routeUrl.length,
  );
  const lang = route.data['site'] === 'museumsdienstBerlin_en' ? 'en' : 'de';
  if (document) document.documentElement.lang = lang;

  return inject(GetTitleGQL)
    .fetch({
      uri: urlWithoutRoute || state.url,
      site: route.data['site'],
    })
    .pipe(
      extractMetaTags(),
      switchMap((result) => {
        const metaTitleContainer = JSON.parse(
          result.data.seomatic?.metaTitleContainer || '{}',
        );

        return result.data.seomatic
          ? of(metaTitleContainer.title.title || 'Museumsdienst Berlin')
          : EMPTY;
      }),
    );
};
