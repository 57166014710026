import { CommonModule } from '@angular/common';
import { Component, effect, inject, signal, viewChild } from '@angular/core';
import {
  NavigationEnd,
  Router,
  RouterLink,
  RouterOutlet,
} from '@angular/router';
import { MatProgressBarModule } from '@angular/material/progress-bar';

import { HeaderComponent } from './components/header/header.component';
import { FooterComponent } from './components/footer/footer.component';
import { LogoComponent } from './components/logo/logo.component';
import { LoaderBarComponent } from './components/loading-bar/loading-bar.component';
import { ToastPopoversComponent } from './components/toast-popovers/toast-popovers.component';
import { SkipContentComponent } from './components/skip-content/skip-content.component';
import { ScrollService } from './utils/services/scroll.service';

import { toSignal } from '@angular/core/rxjs-interop';
import { ScrollTopComponent } from './components/scroll-top/scroll-top.component';
import { A11yModule } from '@angular/cdk/a11y';
import posthog from 'posthog-js';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss',
  host: {
    class: 'layout-grid min-h-lvh',
  },
  imports: [
    CommonModule,
    RouterOutlet,
    RouterLink,
    HeaderComponent,
    FooterComponent,
    LogoComponent,
    MatProgressBarModule,
    LoaderBarComponent,
    ToastPopoversComponent,
    SkipContentComponent,
    ScrollTopComponent,
    A11yModule,
  ],
})
export class AppComponent {
  #scrollService = inject(ScrollService);
  #router = inject(Router);
  readonly #routerEvents = toSignal(this.#router.events);
  outletElRef = viewChild.required<RouterOutlet>('outlet');

  constructor() {
    effect(() => {
      const event = this.#routerEvents();
      if (event instanceof NavigationEnd) {
        posthog.capture('$pageview');
        if (!this.outletActivated()) {
          setTimeout(() => {
            this.#scrollService.scrollToFragment();
          }, 100);
        }
      }
    });
  }

  outletActivated = signal(false);

  setOutletState(state: boolean, delay = false) {
    if (delay) {
      setTimeout(() => {
        this.outletActivated.set(state);
      }, 100);
    } else {
      this.outletActivated.set(state);
    }
  }
}
