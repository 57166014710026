import {
  ApplicationConfig,
  importProvidersFrom,
  isDevMode,
  provideExperimentalZonelessChangeDetection,
} from '@angular/core';
import {
  provideRouter,
  withComponentInputBinding,
  withInMemoryScrolling,
  withPreloading,
  withRouterConfig,
  withViewTransitions,
} from '@angular/router';

import { routes } from './app.routes';
import { provideClientHydration } from '@angular/platform-browser';
import {
  provideHttpClient,
  withFetch,
  withInterceptors,
} from '@angular/common/http';
import { provideGraphQL } from './utils/graphql.provider';
import {
  craftIframeResizerInterceptor,
  craftPreviewInterceptor,
} from '@ynmstudio/utils/interceptors';
import { loadingBarInterceptor } from '@ynmstudio/utils/loading-bar';
import { DATE_PIPE_DEFAULT_OPTIONS, registerLocaleData } from '@angular/common';
import localeDe from '@angular/common/locales/de';
import { QuicklinkStrategy, quicklinkProviders } from 'ngx-quicklink';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';
import { provideA11yService } from './utils/services/a11y.service';
import { provideLogoService } from './components/logo/logo.service';
import { TranslocoHttpLoader } from './utils/transloco-loader';
import { provideTransloco } from '@jsverse/transloco';
import { provideTranslocoLocale } from '@jsverse/transloco-locale';
import { craftErrorHandlingInterceptor } from './utils/interceptors/craft-error-handling.interceptor';
import { provideNavigationService } from './utils/services/navigation.service';
import {
  NgcCookieConsentModule,
  NgcCookieConsentConfig,
} from 'ngx-cookieconsent';
import { MAT_LUXON_DATE_ADAPTER_OPTIONS } from '@angular/material-luxon-adapter';

registerLocaleData(localeDe, 'de');

export const cookieConfig: NgcCookieConsentConfig = {
  cookie: {
    name: 'museumsdienst_cookieconsent_status',
    domain: 'museumsdienst.berlin',
    secure: true,
  },
  type: 'opt-in',
  position: 'bottom-right',
  theme: 'block',
  revokable: false,
  autoOpen: false,
};

export const appConfig: ApplicationConfig = {
  providers: [
    quicklinkProviders,
    provideExperimentalZonelessChangeDetection(),
    provideRouter(
      routes,
      withRouterConfig({
        onSameUrlNavigation: 'reload',
        paramsInheritanceStrategy: 'always',
        // paramsInheritanceStrategy: 'always'
      }),
      withComponentInputBinding(),
      // withViewTransitions(),
      withInMemoryScrolling({
        scrollPositionRestoration: 'disabled',
        anchorScrolling: 'enabled',
      }),
      withPreloading(QuicklinkStrategy),
    ),
    provideClientHydration(),
    provideHttpClient(
      withFetch(),
      withInterceptors([
        craftIframeResizerInterceptor,
        craftPreviewInterceptor,
        craftErrorHandlingInterceptor,
        loadingBarInterceptor,
      ]),
    ),
    provideA11yService(),
    provideNavigationService(),
    provideLogoService(),
    provideGraphQL(),
    {
      provide: DATE_PIPE_DEFAULT_OPTIONS,
      useValue: {
        dateFormat: 'longDate',
        timeZone: 'Europe/Berlin',
      },
    },
    provideAnimationsAsync(),
    provideTransloco({
      config: {
        availableLangs: ['en', 'de'],
        defaultLang: 'de',
        fallbackLang: ['en', 'de'],
        missingHandler: {
          useFallbackTranslation: true,
          allowEmpty: true,
        },
        // Remove this option if your application doesn't support changing language in runtime.
        reRenderOnLangChange: true,
        prodMode: !isDevMode(),
      },
      loader: TranslocoHttpLoader,
    }),
    provideTranslocoLocale({
      langToLocaleMapping: {
        en: 'en-US',
        de: 'de-DE',
      },
      localeConfig: {
        global: {
          date: {
            timeZone: 'Europe/Berlin',
          },
        },
      },
    }),
    {
      provide: MAT_LUXON_DATE_ADAPTER_OPTIONS,
      useValue: {
        firstDayOfWeek: 1,
      },
    },
    importProvidersFrom([NgcCookieConsentModule.forRoot(cookieConfig)]),
  ],
};
